<template>
  <transition name="fade">
    <div class="bg-pink contactForm" v-show="u_store.form">
      <form class="form" @submit.prevent="handleSubmit">
        <h2>Formularz zgłoszeniowy</h2>
        <h2 v-if="formState === 'failed' || formState === 'send'">
          {{
            formState === "send"
              ? "Wysyłanie... do usłyszenia!"
              : "Wystąpił błąd spróbuj ponownie"
          }}
        </h2>
        <div v-if="formState === 'initial'">
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="Your last name.."
            required
          />
        </div>
        <div v-if="formState === 'initial'">
          <textarea
            id="message"
            v-model="message"
            placeholder="Your last name.."
            required
          />
        </div>
        <div v-if="formState === 'sending'" class="spinner"></div>
        <button v-if="formState === 'initial'" class="btn" type="submit">
          Wyślij
        </button>
      </form>
      <div class="instruction">
        <h2>Cześć</h2>
        <p>
          Cieszymy się bardzo że jesteś zainteresowany naszymi zajęciami. Napisz
          do nas wiadomość za pomocą formularza kontaktowego lub napisz maila
          bezaospośrednio: Skontaktujemy się z tobą najszybciej jak to możliwe
          Do usłyszenia!
          <span><h4>Email: biuro@creftyenglish.pl</h4></span>
          <span><h4>Telefon: 000 000 000</h4></span>
        </p>
        <img class="logo" src="@/assets/logo.svg" />
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { uiStore } from "@/store/uiStore";
const u_store = uiStore();
import { ref } from "vue";

const email = ref("");
const message = ref("");
const formState = ref<"initial" | "send" | "failed" | "sending">("initial");

const handleSubmit = async () => {
  formState.value = "sending";
  const url = `https://script.google.com/macros/s/AKfycbziz2_e3fxLYOOy44PcoclaIaVKOWc0lsBJpcWNiFU-cqtHYPnNdYjGunq9aH6p19Y3mQ/exec?mail=${email.value}&msg=${message.value}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (response.ok) {
      // alert("Wiadomość została wysłana!");
      formState.value = "send";
    } else {
      // alert("Wystąpił błąd podczas wysyłania wiadomości.");
      formState.value = "failed";
    }
  } catch (error) {
    // alert("Wystąpił błąd podczas wysyłania wiadomości.");
    formState.value = "failed";
  }
};
</script>

<style scoped>
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.article-container {
  position: absolute;
  top: 10vw;
  left: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.contactForm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.instruction {
  grid-row: 1;
  grid-column: 7/ 11;
  margin: 7vw 0 0 0;
}
.form {
  grid-row: 1;
  grid-column: 2 / 6;
  margin: 7vw 0 0 0;
}
.form input {
  width: 100%;
  padding: 1vw;
  margin: 1vw 0;
  border: none;
  border-radius: 5px;
  font-family: "Courier New", Courier, monospace;
  font-family: "Baloo 2", cursive;
  font-size: 1.5vw;
}
input::placeholder {
  color: #0bf;
}
.form textarea {
  width: 100%;
  height: 20vw;
  padding: 1vw;
  margin: 1vw 0;
  border: none;
  border-radius: 5px;
  font-family: "Baloo 2", cursive;
  font-size: 1.5vw;
  resize: none;
}
textarea::placeholder {
  color: #0bf;
}
button {
  all: unset;
}
.btn {
  width: 10vw;
  height: 3vw;
  margin: 0.4vw 25vw;
  background-color: #346eb6;
  color: white;
  font-size: 1.2vw;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logo {
  width: 10vw;
}
.fade {
  z-index: 2;
}
.fade-enter-active,
.fade-leave-active {
  transition: transform 0.5s ease-out;
}
.fade-enter-from,
.fade-leave-to {
  transform: translateX(100%);
}
</style>
