<template>
  <div class="about-section">
    <div class="article-container">
      <div class="article">
        <p>Hello</p>
        <ul>
          <li>
            Crafty English to autorski program nauczania języka angielskiego
            opracowany przez doświadczonych metodyków językowych ze szkoły
            ElsAngielski, posiadających ponad 30-letnie doświadczenie w
            nauczaniu dzieci. Nasz program jest kompletnym i skutecznym kursem
            językowym, zaprojektowanym tak, aby był nie tylko edukacyjny, ale
            również niezwykle interesujący dla dzieci na każdym etapie ich
            rozwoju.
          </li>
          <li>
            Crafty English wyróżnia się innowacyjnym podejściem do nauczania,
            które integruje zabawę i edukację, stawiając na kreatywne metody
            uczenia się języka angielskiego. Dzięki naszemu autorskiemu
            podejściu, dzieci mogą rozwijać swoje umiejętności językowe w sposób
            naturalny i angażujący, co sprzyja efektywnej nauce oraz utrwaleniu
            zdobytej wiedzy.
          </li>
          <li>
            Nasza metodyka opiera się na przekonaniu, że nauka języka
            angielskiego powinna być przyjemnością, dlatego stworzyliśmy kurs,
            który nie tylko rozwija umiejętności komunikacyjne dzieci, ale także
            pobudza ich ciekawość świata anglojęzycznego. Każdy element Crafty
            English jest starannie dostosowany do potrzeb i możliwości ucznia,
            zapewniając optymalne warunki do nauki i rozwoju językowego.
          </li>
        </ul>
      </div>
    </div>
    <img src="@/assets/red_section_bg.svg" alt="" />
  </div>
</template>

<script setup></script>

<style scoped>
.about-section {
  margin-top: 2.9vw;
  position: relative;
}
.about-section img {
  width: 100vw;
  object-fit: cover;
}

.article-container {
  position: absolute;
  top: 10vw;
  left: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 1vw;
  margin: 0 7vw 0 7vw;
}
.article {
  grid-column: 9 / 13;
  padding-right: 2vw;
}
.article p {
  font-size: 1.8vw;
  font-weight: 700;
  line-height: 0.2;
}
ul {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  list-style-image: url("@/assets/star.svg");
}
li {
  font-size: 1vw;
  font-weight: 300;
  line-height: 1.5;
  margin: 0 0 1vw 0;
}
</style>
