import { defineStore } from "pinia";
export const uiStore = defineStore("storeId", {
  // Stan
  state: (): {
    form: boolean;
  } => ({
    form: false,
  }),
  // Akcje
  actions: {},
  // Gettery
  getters: {},
});
